import binanceWallet from '@binance/w3w-rainbow-connector-v2';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { CreateWalletFn } from '@rainbow-me/rainbowkit/dist/wallets/Wallet';
import {
  bitgetWallet,
  coinbaseWallet,
  gateWallet,
  imTokenWallet,
  ledgerWallet,
  metaMaskWallet,
  okxWallet,
  phantomWallet,
  rabbyWallet,
  safepalWallet,
  tokenPocketWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { Chain } from 'viem';
import {
  arbitrum,
  arbitrumSepolia,
  bsc,
  bscTestnet,
  holesky,
  mainnet,
  polygon,
  polygonAmoy, // xLayer,
  // xLayerTestnet,
  sepolia,
} from 'viem/chains';
import { ChainFormatters } from 'viem/types/chain';
import { createConfig, fallback, http } from 'wagmi';

import { BNB, ETH, POLYGON } from '@/constants/constants';
import { env } from '@/env';

const providerKey = env.NEXT_PUBLIC_ALCHEMY_KEY;
const WC_PROJECT_ID = env.NEXT_PUBLIC_WC_PROJECT_ID;

const wallets: CreateWalletFn[] = [
  metaMaskWallet,
  binanceWallet,
  coinbaseWallet,
  walletConnectWallet,
  okxWallet,
  gateWallet,
  rabbyWallet,
  phantomWallet,
  safepalWallet,
  tokenPocketWallet,
  bitgetWallet,
  imTokenWallet,
  ledgerWallet,
];

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets,
    },
  ],
  {
    appName: 'Stader Labs ETH',
    projectId: WC_PROJECT_ID,
  },
);

type ChainExtra = {
  iconBackground: string;
  iconUrl: string;
  isNativeChain?: boolean;
  hasMultipleStakeToken?: boolean;
};

export type ChainWithCustomData = Chain<ChainFormatters, ChainExtra>;

/**
 * Wagmi seems to be using a different type syntax that isn't satisfied with just `ChainWithCustomData[]`
 */
type ChainsWithCustomDataWagmiFormat = [
  ChainWithCustomData,
  ...ChainWithCustomData[],
];

type ChainsDataConfig = {
  [chain in Chains]: {
    [env in Network]: ChainsWithCustomDataWagmiFormat;
  };
};

const ethMainnetChains: ChainsWithCustomDataWagmiFormat = [
  {
    ...mainnet,
    custom: {
      iconBackground: '#fff',
      iconUrl: '/assets/images/chains/ethereum.svg',
      isNativeChain: true,
    },
  },
  // {
  //   ...optimism,
  //   custom: {
  //     iconBackground: '#000',
  //     iconUrl: '/assets/images/chains/optimism.svg',
  //     isNativeChain: false,
  //   },
  // },

  {
    ...arbitrum,
    custom: {
      iconBackground: '#000',
      iconUrl: '/assets/images/chains/arbitrum.svg',
      isNativeChain: true,
    },
  },

  // {
  //   ...xLayer,
  //   name: 'X Layer',
  //   custom: {
  //     iconBackground: '#000',
  //     iconUrl: '/assets/images/chains/xlayer.svg',
  //     isNativeChain: false,
  //   },
  // },
];

const ethTestnetChains: ChainsWithCustomDataWagmiFormat = [
  {
    ...holesky,
    custom: {
      iconBackground: '#000',
      iconUrl: '/assets/images/chains/ethereum.svg',
      isNativeChain: true,
    },
  },

  {
    ...arbitrumSepolia,
    custom: {
      iconBackground: '#000',
      iconUrl: '/assets/images/chains/arbitrum.svg',
      isNativeChain: true,
    },
  },

  // {
  //   ...xLayerTestnet,
  //   custom: {
  //     iconBackground: '#000',
  //     iconUrl: '/assets/images/chains/xlayer.svg',
  //     isNativeChain: false,
  //   },
  // },
];

const bnbMainnetChains: ChainsWithCustomDataWagmiFormat = [
  {
    ...bsc,
    custom: {
      iconBackground: '#000',
      iconUrl: '/assets/images/chains/bnb.svg',
      isNativeChain: true,
    },
  },
];

const bnbTestnetChains: [ChainWithCustomData] = [
  {
    ...bscTestnet,
    custom: {
      iconBackground: '#000',
      iconUrl: '/assets/images/chains/bnb.svg',
      isNativeChain: true,
    },
  },
];

const polygonMainnetChains: [ChainWithCustomData, ...ChainWithCustomData[]] = [
  {
    ...polygon,
    custom: {
      iconBackground: '#000',
      iconUrl: '/assets/images/chains/polygon.svg',
      isNativeChain: true,
    },
  },
  {
    ...mainnet,
    custom: {
      iconBackground: '#000',
      iconUrl: '/assets/images/chains/eth.svg',
      isNativeChain: false,
      hasMultipleStakeToken: true,
    },
  },
];

const polygonTestnetChains: [ChainWithCustomData, ...ChainWithCustomData[]] = [
  {
    ...polygonAmoy,
    custom: {
      iconBackground: '#000',
      iconUrl: '/assets/images/chains/polygon.svg',
      isNativeChain: true,
    },
  },
  {
    ...sepolia,
    custom: {
      iconBackground: '#000',
      iconUrl: '/assets/images/chains/eth.svg',
      isNativeChain: false,
      hasMultipleStakeToken: true,
    },
  },
];

type Chains = typeof env.NEXT_PUBLIC_TOKEN;
type Network = typeof env.NEXT_PUBLIC_NETWORK;

const chains: ChainsDataConfig = {
  [ETH]: {
    mainnet: ethMainnetChains,
    testnet: ethTestnetChains,
    'preprod-mainnet': ethMainnetChains,
  },
  [BNB]: {
    mainnet: bnbMainnetChains,
    testnet: bnbTestnetChains,
    'preprod-mainnet': bnbMainnetChains,
  },
  [POLYGON]: {
    mainnet: polygonMainnetChains,
    testnet: polygonTestnetChains,
    'preprod-mainnet': polygonMainnetChains,
  },
};

const transports = {
  [ETH]: {
    [mainnet.id]: fallback([
      http(`https://eth-mainnet.g.alchemy.com/v2/${providerKey}`),
      http('https://rpc.ankr.com/eth'),
    ]),
    [holesky.id]: fallback([
      http('https://ethereum-holesky-rpc.publicnode.com'),
      http('https://1rpc.io/holesky'),
    ]),

    // [optimism.id]: fallback([http('https://mainnet.optimism.io')]),
    // [optimismSepolia.id]: fallback([http('https://sepolia.optimism.io')]),

    [arbitrum.id]: fallback([
      http(`https://arb-mainnet.g.alchemy.com/v2/${providerKey}`),
      http('https://arb1.arbitrum.io/rpc'),
    ]),
    [arbitrumSepolia.id]: fallback([
      http('https://sepolia-rollup.arbitrum.io/rpc'),
    ]),

    // [xLayer.id]: fallback([http('https://rpc.xlayer.tech')]),
    // [xLayerTestnet.id]: fallback([http('https://testrpc.xlayer.tech')]),
  },
  [BNB]: {
    [bsc.id]: fallback([
      http(
        `https://bnb-mainnet.g.alchemy.com/v2/PxVAWbaQpx1GaDiBICdGKTu1m6hipTom`,
      ),
    ]),
    [bscTestnet.id]: fallback([
      http(`https://data-seed-prebsc-1-s1.binance.org:8545`),
    ]),
  },
  [POLYGON]: {
    [polygon.id]: fallback([
      http(`https://polygon-mainnet.g.alchemy.com/v2/${providerKey}`),
    ]),
    [mainnet.id]: fallback([
      http(`https://eth-mainnet.g.alchemy.com/v2/${providerKey}`),
      http('https://rpc.ankr.com/eth'),
    ]),
    [polygonAmoy.id]: fallback([
      http(
        `https://polygon-amoy.g.alchemy.com/v2/YfnYF-YOkWoP7696JFENmFQ3OhSzLyOU`,
      ),
    ]),
    [sepolia.id]: fallback([
      http(`https://eth-sepolia.g.alchemy.com/v2/${providerKey}`),
      http('https://ethereum-sepolia-rpc.publicnode.com'),
      http('https://1rpc.io/sepolia'),
    ]),
  },
};

export const wagmiConfig = (chain: Chains) => {
  const network = env.NEXT_PUBLIC_NETWORK;

  return createConfig<
    ChainsWithCustomDataWagmiFormat,
    (typeof transports)[Chains]
  >({
    chains: chains[chain][network],
    transports: transports[chain],
    connectors,
    ssr: true,
  });
};

export type WagmiConfig = ReturnType<typeof createConfig>;
